import { useEffect, useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import Alert from '../common/Alert';
import {
  ENCUESTA_POST_TEST,
  HIDDEN_FORMS,
  INDIRECT_FORMS,
  PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL,
  PROGRAMA_EMPRENDIMIENTO,
} from '../configurations/Configurations';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import Audits from '../components/Audits';

export function BeneficiaryForms({ show, onHide, forms = [], participant }) {
  const [isLoading, setLoading] = useState(true);
  const [auditModal, setAuditModal] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [encuestas, setEncuestas] = useState([...forms]);
  const [openPop, setOpenPop] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const history = useHistory();
  const [encuestaData, setEncuestaData] = useState(null);
  const [encuestaPendientes, setEncuestaPendientes] = useState(null);
  const [selectedEncuesta, setSelectedEncuesta] = useState(null);

  const participant_id = participant?.id;
  useEffect(() => {
    setEncuestas([...forms]);
    setLoading(true);
    if (!participant_id) {
      setLoading(false);
      // do nothing
      return;
    }
    setAlerts([]);
    Promise.all([
      req.get(`${API_URL}config`, { params: { paginacion: 999999 } }),
      req.get(`${API_URL}asistencia/actividades`, {
        params: {
          estado: 'activos',
          participante: participant_id,
          finalizado: 'finalizadas',
        },
      }),
      req.get(`${API_URL}encuestas`, {
        params: {
          estado: 'no_canceladas',
          finalizado: 'finalizadas',
          participante: participant_id,
          paginacion: 9999,
        },
      }),
      req.get(`${API_URL}encuestas`, {
        params: {
          estado: 'no_canceladas',
          participante: participant_id,
          finalizado: 'pendientes',
        },
      })
    ]).then(([responseConfigs, responseAsistencias, responseEncuestas, encuestasPendientes]) => {
      setEncuestaData(responseEncuestas.data.data);
      setEncuestaPendientes(encuestasPendientes.data.data)
      const asistencias = responseAsistencias.data.data || [];
      const configs = responseConfigs.data.data ?? [];

      const encuestaLaboral = configs.find(
        (config) => config.key === 'laboralForm'
      )?.value;
      if (!encuestaLaboral) {
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'No está configurada la encuesta laboral. Ir a "Configuraciones".',
          },
        ]);
      }

      const encuestaSalida = configs.find(
        (config) => config.key === 'exitForm'
      )?.value;
      if (!encuestaSalida) {
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'No está configurada la encuesta de salida. Ir a "Configuraciones".',
          },
        ]);
      }

      const encuestaPostTest = configs.find(
        (config) => config.key === 'postTestForm'
      )?.value;
      if (!encuestaPostTest) {
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'No está configurada la encuesta de post test. Ir a "Configuraciones".',
          },
        ]);
      }

      const programaCapacitacion = configs.find(
        (config) => config.key === PROGRAMA_CAPACITACION_TECNICA_VOCACIONAL
      )?.value;
      if (!programaCapacitacion) {
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'No está configurado el programa de capacitación técnica vocacional. Ir a "Configuraciones".',
          },
        ]);
      }
      const programaEmprendimiento = configs.find(
        (config) => config.key === PROGRAMA_EMPRENDIMIENTO
      )?.value;
      if (!programaEmprendimiento) {
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'No está configurado el programa de emprendimiento. Ir a "Configuraciones".',
          },
        ]);
      }
      //--------------------------------------------------
      // hidden forms filtering

      const hiddenForms = JSON.parse(configs.find(x => x.key === HIDDEN_FORMS)?.value || null) || [];


      setEncuestas(prev => prev.filter(x => {
        const found = hiddenForms.find(y => y.id === x.id);
        return !found;
      }));
      // programa filtering

      const encuestaPostForms = JSON.parse(configs.find(x => x.key === ENCUESTA_POST_TEST)?.value || null) || [];


      setEncuestas(prev => prev.filter(x => {
        const programa = encuestaPostForms.find(y => y.formulario.id === x.id)?.programa;
        if (programa) {
          console.log(`el formulario ${x.nombre} tiene como requisito el programa ${programa.programa}`)
        }
        if (!!programa && !asistencias.some(z => z.actividad.programa.id === programa.id)) {
          console.log(`el formulario ${x.nombre} tiene como requisito el programa ${programa.programa} pero no lo tiene lleno`)
          return false
        }
        return true
      }));

      // directo and indirecto filtering
      const indirectForms = JSON.parse(configs.find(x => x.key === INDIRECT_FORMS)?.value || null) || [];
      const isIndirecto = participant.tipo.id === 2;

      setEncuestas(prev => prev.filter(x => {
        // if it's indirecto only return the form that belong to indirect forms
        if (isIndirecto) {
          return indirectForms.some(indirectForm => indirectForm.id === x.id)
        } else {
          // in other hands if it is not indirect so it is direct then filter by the forms which are not indirect
          return indirectForms.every(indirectForm => indirectForm.id !== x.id)
        }
      }))

      // the participant has not finished any programs
      if (responseAsistencias.data.data.length === 0) {
        setEncuestas((prev) => {
          const exitFormIndex = prev.findIndex(
            (form) => form.id.toString() === encuestaSalida
          );
          // remove exit form from the list
          if (exitFormIndex >= 0) {

            prev.splice(exitFormIndex, 1);
          }
          return [...prev];
        });
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message:
              'El participante aún no ha participado en ningún programa.',
          },
        ]);
      }
      //--------------------------------------------

      const hasFinishedTraining = responseAsistencias.data.data
        .map((asistencia) => asistencia.actividad.programa)
        .some(
          (programa) =>
            programa.id.toString() === programaCapacitacion ||
            programa.id.toString() === programaEmprendimiento
        );

      if (!hasFinishedTraining) {
        setEncuestas((prev) => {
          const laboralFormIndex = prev.findIndex(
            (form) => form.id.toString() === encuestaLaboral
          );
          // remove the laboral form from the list
          if (laboralFormIndex >= 0) {
            prev.splice(laboralFormIndex, 1);
          }
          // remove the post test form
          const postTestFormIndex = prev.findIndex(
            (form) => form.id.toString() === encuestaPostTest
          );
          if (postTestFormIndex >= 0) {
            prev.splice(postTestFormIndex, 1);
          }
          return [...prev];
        });
        setAlerts((prev) => [
          ...prev,
          {
            show: true,
            type: 'alert-warning',
            message: 'El participante no ha completado un programa de Capacitación técnico vocacional y/o Emprendimiento.',
          },
        ]);
      }

      setEncuestas((prev) => {
        const result = prev.filter((enc) => {
          const { requisito } = enc;
          return (
            requisito === null ||
            responseEncuestas.data.data.some(
              (enc) => enc.formulario_id === requisito
            )
          );
        });
        return [...result];
      });

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant_id]);

  const handleOpenModal = (form) => {
    const selected = encuestaPendientes.filter(ep => ep.formulario_id === form.id);

    if (selected.length === 0) {
      history.push(`/formularios/${form.id}/${participant?.id}`);
      return;
    }
    if (!selected[0].popup) {
      history.push(`/formularios/${form.id}/${participant?.id}`);
      return;
    }

    setSelectedEncuesta(selected[0]);
    setSelectedForm(form);
    setOpenPop(true);
  };

  const isFirstTime = (form) => {
    if (!encuestaData) {
      return false;
    }

    const formExists = encuestaData.some((x) => x.formulario_id === form.id);
    return formExists
  };

  const handleContinue = () => {
    if (selectedForm) {
      history.push(`/formularios/${selectedForm.id}/${participant?.id}`);
    }
    setOpenPop(false);
  };
  return (
    <div>
      <Modal centered size="lg" show={show} onHide={onHide}>
        {!!auditModal && (
          <Audits
            close={() => setAuditModal(null)}
            show={!!auditModal}
            tipo={"encuestas"}
            id={auditModal}
          />
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Encuestas de beneficiario (ID: {participant?.codigo})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpinnerPlaceholder isLoading={isLoading}>
            {encuestas.map((form) => (
              <div key={form.id} className="d-block">
                {isFirstTime(form) ? (
                  <Link to={`/formularios/${form.id}/${participant?.id}`}>
                    {`${form.nombre}`}
                  </Link>
                ) : (

                  <a onClick={() => handleOpenModal(form)} style={{ cursor: "pointer", color: "#429ffd" }}>
                    {`${form.nombre}`}
                  </a>

                )}
              </div>
            ))}
          </SpinnerPlaceholder>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex flex-column w-100">
            {alerts.map((alert, i) => (
              <Alert key={i} {...alert} />
            ))}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openPop}
        onHide={() => setOpenPop(false)}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            {selectedEncuesta?.popup_titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          <p>{selectedEncuesta?.popup_cuerpo}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-center'>
            <button
              type='button'
              className='btn rounded-pill me-2'
              style={{ background: "red" }}
              onClick={() => setOpenPop(false)}
            >
              Cancelar
            </button>
            <button variant="primary" onClick={handleContinue}>
              Continuar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
