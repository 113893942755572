import React, { Component } from 'react';
import { Container, Nav, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import Audits from '../components/Audits.js';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import Alerts from '../common/Alerts.js';
import {
  changeDateFormat,
  checkStatus,
} from '../components/functions.js';
import { req } from '../utils/request.js';
import $ from 'jquery';
import { API_URL } from '../utils/config.js';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import { ROLE_SYSTEM_ADMIN } from '../utils/roles.js';
import { BeneficiaryForms } from './BeneficiaryForms.jsx';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

class BeneficiariesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      entryForm: '',
      exitForm: '',
      laboralForm: '',
      laboralForm_Validations: [],
      activitiesattendances: [],
      alertShow: false,
      alertMessage: '',
      alertType: '',
      organizaciones: [],
      forms: [],
      popupIndirect: false,
      popupNoConfigured: false,
      popupNoConfiguredData: '',
      participants: [],
      auditType: 'participantes',
      showAudit: null,
      showFormsModal: false,
      formsModalParticipant: null,
    };

    this.show_PopUpIndirecto = this.show_PopUpIndirecto.bind(this);
    this.hide_PopUpIndirecto = this.hide_PopUpIndirecto.bind(this);

    this.show_PopUpNoConfigured = this.show_PopUpNoConfigured.bind(this);
    this.hide_PopUpNoConfigured = this.hide_PopUpNoConfigured.bind(this);

    this.action_RowOptions = this.action_RowOptions.bind(this);
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activateRow = this.activateRow.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
  }
  show_PopUpIndirecto(data) {
    this.setState({ popupIndirect: true });
  }
  hide_PopUpIndirecto() {
    this.setState({ popupIndirect: false });
  }
  show_PopUpNoConfigured(text) {
    this.setState({ popupNoConfigured: true, popupNoConfiguredData: text });
  }
  hide_PopUpNoConfigured() {
    this.setState({ popupNoConfigured: false, popupNoConfiguredData: '' });
  }

  showAudit(id) {
    this.setState({
      showAudit: id,
    });
  }
  closeAudit() {
    this.setState({
      showAudit: null,
    });
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}participantes/${data.id}/toggle`).then(() => {
      this.props.history.push({
        pathname: '/beneficiarios',
        state: {
          alertShow: true,
          alertType: 'success',
          alertMessage: 'El beneficiario ha sido desactivado con éxito',
        },
      });
    });
  }
  activateRow(data) {
    data.activo = true;
    req.post(`${API_URL}participantes/${data.id}/toggle`).then(() => {
      this.props.history.push({
        pathname: '/beneficiarios',
        state: {
          alertShow: true,
          alertType: 'success',
          alertMessage: 'El beneficiario ha sido activado con éxito',
        },
      });
    });
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        alertShow: this.props.location.state.alertShow,
        alertMessage: this.props.location.state.alertMessage,
        alertType: this.props.location.state.alertType,
      });
    }

    $('.nav-link').removeClass('active');
    $('h1').children('span').remove();
    $('h1').children('em').remove();
    $(".nav-link[href='/beneficiarios']").addClass('active');
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);

    req.get(`${API_URL}selectores/organizaciones`).then((response) => {
      this.setState({ organizaciones: response.data.data ?? [] });
    });
    req.get(`${API_URL}asistencia/actividades`).then((response) => {
      this.setState({ activitiesattendances: response.data.data ?? [] });
    });
    req.get(`${API_URL}selectores/formularios`).then((response) => {
      this.setState({ forms: response.data.data ?? [] });
    });
    req.get(`${API_URL}config`).then((response) => {
      const configs = response.data.data ?? [];
      let entryForm = '';
      let exitForm = '';
      let laboralForm = '';
      let validations = [];
      configs.forEach((config) => {
        switch (config.key) {
          case 'laboralForm':
            laboralForm = config.value;
            break;
          case 'entryForm':
            entryForm = config.value;
            break;
          case 'exitForm':
            exitForm = config.value;
            break;
          default:
            // validations = JSON.parse(config.value);
            break;
        }
      });
      this.setState({
        entryForm,
        exitForm,
        laboralForm,
        laboralForm_Validations: validations,
      });
    });
  }

  editRow(data) {
    this.props.history.push('/beneficiarios/' + data.id);
  }

  action_RowOptions(action, data) {
    switch (action) {
      case 'Llenar Inscripción a Participantes':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Inscripción a Participantes siendo un Participante Indirecto'
          );
        } else {
          window.location.replace(
            '/formularios/' + this.state.entryForm[0].value + '/' + data.data.id
          );
        }
        break;
      case 'Editar Inscripción a Participantes':
        if (
          this.state.entryForm[0] !== undefined &&
          this.state.entryForm[0].value !== null
        ) {
          window.location.replace(
            '/formularios/' +
            this.state.entryForm[0].value +
            '/' +
            data.data.id +
            '/' +
            data.data.entryFormAnswers
          );
        }
        break;
      case 'Llenar Encuesta de Salida':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Inscripción a Participantes siendo un Participante Indirecto'
          );
        } else {
          if (
            this.state.exitForm[0] !== undefined &&
            this.state.exitForm[0].value !== null
          ) {
            checkIfCanExitForm(
              this,
              data.data,
              this.state.activitiesattendances,
              this.state.participants
            );
          }
        }
        break;
      case 'Editar Encuesta de Salida':
        window.location.replace(
          '/formularios/' +
          this.state.exitForm[0].value +
          '/' +
          data.data.id +
          '/' +
          data.data.exitFormAnswers
        );
        break;
      case 'Llenar Encuesta Laboral':
        if (data.data.colateral === false) {
          this.show_PopUpNoConfigured(
            'No es posible llenar una Encuesta de Laboral sin tener una Encuesta de Inscripción a Participantes'
          );
        } else {
          if (
            this.state.laboralForm[0] !== undefined &&
            this.state.laboralForm[0].value !== null
          ) {
            checkIfCanLaboralForm(
              this,
              data.data,
              this.state.laboralForm_Validations,
              'crear',
              this.state.activitiesattendances
            );
          }
        }
        break;
      case 'Editar Encuesta Laboral':
        if (
          this.state.laboralForm[0] !== undefined &&
          this.state.laboralForm[0].value !== null
        ) {
          checkIfCanLaboralForm(
            this,
            data.data,
            this.state.laboralForm_Validations,
            'editar',
            this.state.activitiesattendances
          );
        }
        break;
      default:
        break;
    }
  }
  render() {
    const {
      alertMessage,
      alertShow,
      alertType,
      popupIndirect,
      forms,
      popupNoConfigured,
      popupNoConfiguredData,
    } = this.state;

    const auth = JSON.parse(localStorage.getItem('user'));
    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className='titleButtons'>
          <Nav className='justify-content-end'>
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className='nav-link' to='/registro'>
                <span>Agregar nuevo beneficiario</span>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className='nav-link' to='/actividades'>
                <span>Ver Actividades</span>
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames='transitionPage'
        >
          <div className='mainBox beneficiaries_list'>
            <MaterialTable
              title=''
              columns={[
                {
                  title: 'ID',
                  field: 'codigo',
                  render: (rowData) => (
                    <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                      {rowData.codigo}
                    </Link>
                  ),
                },
                {
                  title: 'Nombre',
                  field: 'nombres',
                  render: (rowData) => (
                    <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                      {rowData.nombres}
                    </Link>
                  ),
                  customFilterAndSearch: (term, rowData) =>
                    (
                      (rowData.name !== null ? rowData.name.toLowerCase() : '') +
                      ' ' +
                      (rowData.lastname !== null
                        ? rowData.lastname.toLowerCase()
                        : '')
                    ).indexOf(term !== null ? term.toLowerCase() : '') !== -1,
                },
                {
                  title: 'Apellido',
                  field: 'apellidos',
                  render: (rowData) => (
                    <Link to={`/beneficiarios/detalle/${rowData.id}`}>
                      {rowData.apellidos}
                    </Link>
                  ),
                },
                {
                  title: 'Organización',
                  field: 'organizacion',
                  hidden:
                    auth?.user?.role?.nombre !== ROLE_SYSTEM_ADMIN
                      ? true
                      : false,
                  render: (rowData) => rowData?.organizacion?.nombre,
                  customFilterAndSearch: (term, rowData) =>
                    rowData.organizacion.nombre
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) !== -1,
                },
                {
                  title: 'Fecha de Registro',
                  field: 'registro',
                  render: (rowData) => changeDateFormat(rowData.registro),
                },
                {
                  title: 'Fecha de Nacimiento',
                  field: 'fecha_nacimiento',
                  render: (rowData) =>
                    changeDateFormat(rowData.fecha_nacimiento),
                },
                {
                  title: 'Sexo',
                  field: 'genero',
                  render: (rowData) =>
                    rowData.genero.charAt(0).toUpperCase() +
                    rowData.genero.slice(1),
                },
                {
                  title: 'Tipo de Participante',
                  field: 'tipo.nombre',
                },
                {
                  title: 'Estatus',
                  field: 'activo',
                  render: (rowData) => checkStatus(rowData.activo),
                },
                { title: '', field: 'entryForm', hidden: 'false' },
                { title: '', field: 'entryFormAnswers', hidden: 'false' },
                { title: '', field: 'exitForm', hidden: 'false' },
                { title: '', field: 'exitFormAnswers', hidden: 'false' },
                { title: '', field: 'laboralForm', hidden: 'false' },
                { title: '', field: 'laboralFormAnswers', hidden: 'false' },

                {
                  title: 'Acciones',
                  searchable: true,
                  cellStyle: {
                    minWidth: 250,
                    maxWidth: 250,
                  },
                  field: 'actions',
                  render: (rowData) => (
                    <>
                      {rowData.activo ? (
                        <>

                          <IconButton
                            onClick={() =>
                              this.setState({
                                showFormsModal: true,
                                formsModalParticipant: rowData,
                              })
                            }
                          >
                            <MoreHorizIcon />
                          </IconButton>

                          <IconButton onClick={() => this.editRow(rowData)}>
                            <ModeEditOutlinedIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => this.desactivateRow(rowData)}
                          >
                            <DoNotDisturbOnOutlinedIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton onClick={() => this.activateRow(rowData)}>
                          <CheckCircleOutlinedIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={() => this.showAudit(rowData.id)}>
                        <HistoryIcon />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              localization={{
                header: { actions: '' },
                toolbar: {
                  searchTooltip: 'Buscar',
                  searchPlaceholder: 'Buscar',
                },
                pagination: {
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Página siguiente',
                  firstTooltip: 'Primera página',
                  lastTooltip: 'Última página',
                },
                body: {
                  emptyDataSourceMessage: 'No hay registros que mostrar',
                },
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: 'right',
                actionsColumnIndex: -1,
                paging: true,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                draggable: false,
              }}
              data={(query) => {
                return new Promise((resolve) => {
                  const params = {
                    page: query.page + 1,
                    paginacion: query.pageSize,
                  };
                  if (query.search) {
                    params.busqueda = query.search;
                  }
                  if (query.orderBy) {
                    const sort = `${query.orderBy.field},${query.orderDirection}`;
                    params.ordenar = sort;
                  }
                  if (auth.user.role.nombre !== ROLE_SYSTEM_ADMIN) {
                    params.organizacion = auth.user.organizacion.id;
                  }
                  // TODO implement filters
                  req
                    .get(API_URL + 'participantes', { params })
                    .then((response) => {
                      resolve({
                        data: response.data.data,
                        page: response.data.meta.current_page - 1,
                        totalCount: response.data.meta.total,
                      });
                    });
                });
              }}
            />
          </div>
        </CSSTransition>

        <Modal centered show={popupIndirect} onHide={this.hide_PopUpIndirecto}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: 'center' }}>
              No es posible llenar una Encuesta de Salida sin tener una Encuesta
              de Inscripción a Participantes
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hide_PopUpIndirecto}>Aceptar</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={popupNoConfigured}
          onHide={this.hide_PopUpNoConfigured}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: 'center' }}>{popupNoConfiguredData}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hide_PopUpNoConfigured}>Aceptar</Button>
          </Modal.Footer>
        </Modal>

        <BeneficiaryForms
          participant={this.state.formsModalParticipant}
          forms={forms}
          show={this.state.showFormsModal}
          onHide={() =>
            this.setState({
              showFormsModal: false,
              formsModalParticipant: null,
            })
          }
        />
      </Container>
    );
  }
}

function checkIfCanLaboralForm(
  these,
  data,
  checkIfCanLaboralForm,
  type,
  activitiesattendances
) {
  req
    .get(API_URL + 'participant_activity', {
      params: { uuid: data.uuid, activo: true },
    })
    .then((res) => {
      let result = res.data.data || res.data;

      let pass = false;
      let validation = 1;

      if (result.length === 0) {
        pass = true;
      }
      Object.keys(result).forEach(function (key) {
        if (
          result[key].program_type_id === '6241dae9be360f18dd2c4493' ||
          result[key].program_type_id === '6241dacb229bb80ff37d6442'
        ) {
          activitiesattendances.forEach((row) => {
            if (
              row.status === true &&
              row.position === result[key].activity_id &&
              row.participant.uuid === data.uuid
            ) {
              pass = true;
              validation = 1;
            }
          });
        }
      });

      let pass2 = true;
      data.answer_forms.forEach((af) => {
        if (af.type === 'ET') {
          checkIfCanLaboralForm[0].value.forEach((lf) => {
            if (
              af.data['field_' + lf.form_field_id] === lf.answer &&
              af.data['field_' + lf.form_field_id] !== null &&
              af.data['field_' + lf.form_field_id] !== undefined
            ) {
              pass2 = false;
              validation = 2;
            }
          });
        }
      });

      if (pass && pass2) {
        if (type === 'crear') {
          window.location.replace(
            '/formularios/' + these.state.laboralForm[0].value + '/' + data.id
          );
        } else {
          window.location.replace(
            '/formularios/' +
            these.state.laboralForm[0].value +
            '/' +
            data.id +
            '/' +
            data.laboralFormAnswers
          );
        }
      } else {
        if (validation === 1) {
          these.show_PopUpNoConfigured(
            'El participante no ha completado un programa Capacitación Técnico Vocacional y/o Autoempleo/Emprededurismo'
          );
        } else {
          these.show_PopUpNoConfigured(
            'La Encuesta Laboral existente no cumple las condiciones para ser editada: Este beneficiario/a tiene un empleo o negocio'
          );
        }
      }
    });
} // formular, action,  programID(Resiliente),

function checkIfCanExitForm(these, data, activitiesattendances, participants) {
  req
    .get(API_URL + 'participant_activity', {
      params: { uuid: data.uuid, activo: true },
    })
    .then((res) => {
      let result = res.data.data || res.data;

      let pass = false;
      let activity_id = '';
      if (result.length === 0) {
        pass = true;
      }
      Object.keys(result).forEach(function (key) {
        activitiesattendances.forEach((row) => {
          if (
            row.uuid_id === data.uuid &&
            row.status === true &&
            row.position === result[key].activity_id
          ) {
            pass = true;
          }
        });
      });
      if (pass) {
        window.location.replace(
          '/formularios/' + these.state.exitForm[0].value + '/' + data.id
        );
      } else {
        these.show_PopUpNoConfigured(
          'El o la  participante no ha completado un programa'
        );
      }
    });
}
export default withRouter(BeneficiariesList);
