
  import FileDownloadIcon from '@mui/icons-material/FileDownload';
  import MaterialTable from 'material-table';
  import * as Yup from 'yup';
  import { useState, useEffect, useMemo } from 'react'; 
  import { Button, Modal, Form, Row, Col} from 'react-bootstrap';
  import { Formik, Field, ErrorMessage } from 'formik';
  import { Link, withRouter } from 'react-router-dom';
  import Header from '../common/Header';
  import { changeDateFormat } from '../components/functions';
  import AttendanceDates from '../components/AttendanceDates';
  import { API_URL } from '../utils/config';
  import Utils from '../utils/config';
  import { req } from '../utils/request.js';
  import axios from 'axios';
  import { ExportToCsv } from 'export-to-csv';
  import FS from "../components/FieldStructure";
  import AuthService from '../services/auth.service';  
  import activityService from '../services/activities';
  import attendanceService from '../services/attendances';
  // import { ROLE_SYSTEM_ADMIN } from '../utils/roles'; 
  
  const API = Utils.API;
  export function AttendanceList(props) {  
  
    const [isLoading, setLoading] = useState(true);
  const auth = AuthService.getCurrentUser();
  // const isAdmin = auth?.user?.role?.nombre === ROLE_SYSTEM_ADMIN;
  const [asistencias, setAsistencias] = useState([]);
  const activity_id = props.match.params.activity_id;
  const [actividad, setActividad] = useState({});
  const [addDate, setAddDate] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [dateAsistencia, setDateAsistencia] = useState([]);
  const [onlyDates, setOnlyDates] = useState([]);
  const [allDates, setAllDate] = useState([]);
  
  // const [editingData, setEditingData] = useState(false);
  const [participants, setParticipants] = useState([]);
  
  const fechas = (rowData) => {
    return <p>{rowData.participante.nombres}</p>
  }
  function checkTipoAsistencia(data){
    switch (data) {
      case 1:
        return "P";
        break;
      case 2:
        return "T";
        break;
      case 3:
        return "A";
        break;
      case 4:
        return "R";
        break;
      case 5:
        return "E";
        break;
      default:
        return "-";
        break;
    }
  }
  function setProperty(id, newItem) { 
    if (('porcentaje' in newItem) && (newItem.porcentaje < 0 || newItem.porcentaje > 100)) {
      alert('Debes colocar un valor entre 0 y 100')
      return
    }

    setAsistencias(asistenciaData => {
      const newAsistencia = asistenciaData.map(asistencia => {
        if (asistencia.id === id) {
          const merged = { ...asistencia, ...newItem }
          updateAsistenciaActividades(merged);
          return merged;
        }
        return asistencia;
      })

      return newAsistencia;
    });

  }

  const downloadCsv = () => {
    if (asistencias.length === 0) {
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      filename: 'asistencia',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    const csvData = [];
    for (let elem of asistencias) {
      const row = { "UUID": elem.participante.codigo, 
      "Nombres": elem.participante.nombres, 
      "Apellidos": elem.participante.apellidos, 
      "Tipo de Participante": elem.participante.tipo.nombre,
    "Presente": elem.presente,
    "Tardanza": elem.tardanza,
    "Ausencia": elem.ausencia,
    "Porcentaje": elem.porcentaje,
    "Observación": elem.observacion,
    "Motivo": elem.motivo,
  };
  onlyDates.map((date, dataIndex) => {
    dateAsistencia.map((data, dataIndex) => {
      if (data.participanteId === elem.participante.id) {
        return ( 
          data.asistencia.map((asistencia, asistenciaIndex) => (
            asistencia.fecha === date ? row[date] = checkTipoAsistencia(asistencia?.tipo_asistencia?.id): null
          ))
        );
      }})
  })
  
      
      csvData.push(row);
    }
    csvExporter.generateCsv(csvData);
  };
  const handleAttendanceChange = async () => {  
    await fetchData();
  }
  useEffect(() => {
    fetchData();
  }, [activity_id]);
  async function updateAsistenciaActividades(payload) {
    const { participante, actividad, observacion, motivo, porcentaje, id } = payload;

    const formData = new FormData();
    formData.append('participante', participante.id);
    formData.append('actividad', actividad.id);
    observacion && formData.append('observacion', observacion === 'Selecciona' ? '' : observacion);
    motivo && formData.append('motivo', motivo === 'Selecciona' ? '' : motivo);
    porcentaje && formData.append('porcentaje', porcentaje);
    req.post(API_URL + `asistencia/actividades/${id}/update`, formData);

  }
  const getLookup = (items) => items.reduce((acc, current) => ({ ...acc, [current]: current }), {});

  const memoAsistencias = useMemo(() => asistencias.map(
    asistencia => {
      const voluntariamente = 'Voluntariamente dejó de asistir a los servicios o programas';
      const deserto = 'Desertó';
      return {
        ...asistencia,
        porcentaje: asistencia.porcentaje || null,
        observacion: asistencia.observacion || 'Selecciona',
        motivo: [voluntariamente, deserto].includes(asistencia.observacion) ? (asistencia.motivo || 'Selecciona') : null,
      }
    }), [asistencias]);

  const fetchData = async () => {


    attendanceService.query({actividades: activity_id, paginacion: 99999999}).then(response => {
      const allDates = response.data.data.map(item => item.fecha); 
      const uniqueDates = [...new Set(allDates)]; 
      uniqueDates.sort();  
      setAllDate(response.data.data);
      setOnlyDates(uniqueDates);
      const groupedData = response.data.data.reduce((acc, item) => {
        const { id: participanteId } = item.participante;
        const { fecha, tipo_asistencia, id, asistencia_actividad } = item;
      
        // Si el participante no existe en el objeto acumulador, inicializarlo
        if (!acc[participanteId]) {
          acc[participanteId] = {
            participanteId,
            asistencia_actividad,
            asistencia: []
          };
        }
      
        // Agregar la fecha y el tipo de asistencia al array de asistencia
        acc[participanteId].asistencia.push({
          fecha,
          tipo_asistencia,
          asistencia_actividad,
          id
        });
      
        return acc;
      }, {});
      
      // Convertir el objeto a un array
      const resultArray = Object.values(groupedData); 
      setDateAsistencia(resultArray)

      fetchAdditionalData(resultArray);
    });
    const fetchAdditionalData = async (dateAsistencia) => {
      const actividadResponse = await activityService.find(activity_id);
      setActividad(actividadResponse.data.data);
      const asistenciasResponse = await req.get(
        `${API_URL}asistencia/actividades`,
        {
          params: {
            actividad: activity_id,
            paginacion: 999999,
          },
        }
      );  
      const asistenciaPorParticipante = {};

      
      dateAsistencia.forEach(participante => {
        const { participanteId, asistencia, asistencia_actividad } = participante; 
        const presente = asistencia.filter(a => a.tipo_asistencia && a.tipo_asistencia.id === 1).length;
        const tardanza = asistencia.filter(a => a.tipo_asistencia && a.tipo_asistencia.id === 2).length;
        const ausencia = asistencia.filter(a => a.tipo_asistencia && a.tipo_asistencia.id === 3).length; 
      
        let calculo =  ((presente + tardanza) / actividadResponse.data.data.meta) * 100;
        calculo = Math.round(calculo);
        let porcentaje = asistencia_actividad !== null ? asistencia_actividad?.porcentaje || 0 : null ;
        // const porcentaje = asistencia_actividad.porcentaje; 
        if(porcentaje !== calculo || porcentaje === null){
           porcentaje = calculo;
           if(asistencia_actividad !== null){
            updateAsistenciaPorcentaje(participanteId, calculo, asistencia_actividad.id);
          }
        } 
        asistenciaPorParticipante[participanteId] = {
            presente,
            tardanza,
            ausencia,
            porcentaje
        };
    });
      
      const asistenciaFinal = asistenciasResponse.data.data.map(item => {
        const participanteId = item.participante.id;
        return {
          ...item,
          presente: asistenciaPorParticipante[participanteId]?.presente || 0,
          tardanza: asistenciaPorParticipante[participanteId]?.tardanza || 0,
          ausencia: asistenciaPorParticipante[participanteId]?.ausencia || 0, 
          porcentaje: asistenciaPorParticipante[participanteId]?.porcentaje || 0, 
        } 
      }); 
      setAsistencias(asistenciaFinal.map(
        asistencia => {
          const voluntariamente = 'Voluntariamente dejó de asistir a los servicios o programas';
          const deserto = 'Desertó';
          return {
            ...asistencia,
            observacion: asistencia.observacion || 'Selecciona',
            motivo: [voluntariamente, deserto].includes(asistencia.observacion) ? (asistencia.motivo || 'Selecciona') : null,
          }
        }
      ));
      const participantsArray = asistenciasResponse.data.data.map(item => item.participante);
 
      setParticipants(participantsArray);

      setLoading(false);
    };

  };

  async function updateAsistenciaPorcentaje(participanteId, calculo, id ) { 

    const formData = new FormData();
    formData.append('participante', participanteId);
    formData.append('actividad', activity_id); 
    calculo && formData.append('porcentaje', calculo);
    req.post(API_URL + `asistencia/actividades/${id}/update`, formData); 
  }

  const columns = [
    {
      title: 'UUID',
      field: 'participante.codigo',
      editable: 'never',

    },
    {
      title: 'id',
      field: 'id',
      editable: 'never',
      hidden: true
    },
    {
      title: 'id',
      field: 'participante.id',
      editable: 'never',
      hidden: true
    },
    {
      title: 'Nombres',
      field: 'participante.nombres',
      editable: 'never',
    },
    {
      title: 'Apellidos',
      field: 'participante.apellidos',
      editable: 'never',
    },
    {
      title: 'Sexo',
      field: 'participante.genero',
      editable: 'never',
      render: (rowData) =>
        rowData.participante.genero.charAt(0).toUpperCase() +
        rowData.participante.genero.slice(1),
    },
    {
      title: 'Tipo de Participante',
      field: 'participante.tipo.nombre',
      editable: 'never',
    }, 
    {
      title: 'Fecha de nacimiento',
      editable: 'never',
      render: (rowData) =>
        changeDateFormat(rowData.participante.fecha_nacimiento),
    },
    {
      title: 'P',
      field: 'presente',
      sorting: false,  
      align: 'center', 
      editable: 'never'
    },
    {
      title: 'T',
      field: 'tardanza',
      sorting: false, 
      align: 'center',
      type: 'numeric',
      editable: 'never', 
    },
    {
      title: 'A',
      field: 'ausencia',
      sorting: false, 
      type: 'numeric', 
      align: 'center',
      editable: 'never', 
    },
    {
      title: 'Meta',
      sorting: false, 
      align: 'center',
      field: 'actividad.meta',
      type: 'numeric', 
      editable: 'never', 
    },
    {
      title: 'Porcentaje',
      field: 'porcentaje', 
      editable: 'never', 
      render: (rowData) => rowData.porcentaje !== null ? rowData.porcentaje + "%" : 0 + "%"
    },
    {
      title: 'Observación',
      field: 'observacion',
      lookup: getLookup(['Selecciona', 'Completó asistencia a servicios o programas en que participó',
              'Falleció', 'No completó el porcentaje del programa', 'Voluntariamente dejó de asistir a los servicios o programas',
              'Desertó']),
    },
    {
      title: 'Motivo',
      field: 'motivo',
      lookup: getLookup(['Selecciona', 'Por falta de motivación o interés',
        'Se mudó de la comunidad', 'Por estudios', 'Distancia/Lejanía',
        'Cuidar a un familiar', 'No localizado/a', 'Por falta de recursos económicos']),
      
    },
    ...onlyDates.map((date, index) => {
      const isLastDate = (index + 1) === onlyDates.length;
      let dateFormated = cambiarFormatoFecha(date);
      return {
        title: dateFormated,
        sorting: false,
        align: 'center',
        editable: 'never',
        field: `asistencia.${dateFormated}`,
        render: (rowData) => (dateAsistencia.length != 0) ? 
          <AttendanceDates 
            onAttendanceChange={handleAttendanceChange} 
            rowData={rowData} 
            onlyDates={date} 
            dateAsistencia={dateAsistencia} 
            isLastDate={isLastDate} // Paso el prop adicional cuando es el último date
          /> 
          : null
      };
    })
  ];
    return (
      <>
      <Header
        back='/actividades'
        title={isLoading ? '' : `Asistencia de ${actividad?.nombre}`}
        subtitle={
          isLoading
            ? ''
            : `Número de Actividad: \u00a0${actividad.id} \u00a0\u00a0\u00a0\u00a0\u00a0 Organización: ${actividad?.organizacion?.nombre} \u00a0\u00a0\u00a0\u00a0\u00a0 Programa: ${actividad?.programa?.nombre}`
        }
      />
      <div className='container' style={{ position: 'relative' }}>
        <div className='titleButtons'>
          <div className='justify-content-end nav'>
            {/* Alerts */}
            <div className={`alert ${alert.show ? 'show' : ''} ${alert.type}`}>
              <p>{alert.message}</p>
            </div>
          <Link to={`/actividades/${activity_id}/asistencias`} className='nav-item'>
            <span className='nav-link'>Ver Actividad</span>
          </Link>
            <Button  style={{height: "43px"}} className='nav-link' onClick={() => setAddDate(true)}>+ Agregar Fecha</Button>
            <Button  style={{height: "43px"}} className='nav-link' onClick={() => setEditDate(true)}>Editar Fecha</Button>
          </div>
        </div>
        <div className='mainBox'>
        <p className='conteo'>
              Cantidad total de participantes: {asistencias.length}
            </p>
        <div className='d-flex justify-content-end'>
          {asistencias.length > 0 && (
            <button
              type='button'
              className='btn btn-secondary'
              onClick={downloadCsv}
              title='Descargar CSV'
            >
              <FileDownloadIcon />
            </button>
          )}
        </div>
          <MaterialTable
            title='' 
            columns={columns}
            data={memoAsistencias} 
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve, reject) => {
                  setProperty(rowData.id, { [columnDef.field]: newValue })
                  resolve()
                });
              }
            }}
            localization={{
              header: {
                actions: 'Acciones',
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
              },
              pagination: {
                previousTooltip: 'Página anterior',
                nextTooltip: 'Página siguiente',
                firstTooltip: 'Primera página',
                lastTooltip: 'Última página',
              },
              body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
              },
            }}
            options={{
              search: true,
              selection: false,
              searchFieldAlignment: 'right',
              actionsColumnIndex: -1,
              paging: true,  
              pageSize: 20,
              emptyRowsWhenPaging: false,
              draggable: false,  
              // fixedColumns: {
              //   left: 0,
              //   right: onlyDates.length
              // }
            }}
          />
          <p style={{fontSize: '12px'}}><strong style={{display: 'inline-block'}}>Leyenda:</strong> 
          <strong style={{marginLeft: '10px', display: 'inline-block'}}>P:</strong> Presente,
          <strong style={{marginLeft: '10px', display: 'inline-block'}}>A:</strong> Ausente, 
          <strong style={{marginLeft: '10px', display: 'inline-block'}}>R:</strong> Retirado, 
          <strong style={{marginLeft: '10px', display: 'inline-block'}}>T:</strong> Tardanza, 
          <strong style={{marginLeft: '10px', display: 'inline-block'}}>E:</strong> Excusa </p>
        </div>
        <Modal
          centered
          show={addDate}
          onHide={() => setAddDate(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Agregar Fecha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                fecha: '',
                tipo_asistencia: 1
              }}
              validationSchema={Yup.object().shape({
                fecha: Yup.string().required('El nombre es un campo requerido.'), 
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.tipo_asistencia = 1;
                let auth = JSON.parse(localStorage.getItem('user'));
                let access_token = auth.access_token;

                  if (participants.length > 0) {
                        asistencias.forEach(as => {
                         values.asistencia_actividad = as.id;
                          axios
                            .post(API + 'asistencia/actividades/asistencia/store', values, {
                              headers: { Authorization: 'Bearer ' + access_token },
                            })
                            .then((response) => {
                              console.log(`Asistencia registrada para participante ${as.id}`);
                            })
                            .catch((error) => {
                              console.error(`Error al registrar asistencia para participante ${as.id}:`, error);
                            });
                        });
                      }
                setAddDate(false)
                setTimeout(function(){
                  window.location.reload();
                }, 1000);

                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, handleBlur, isSubmitting, errors, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <FS label='Fecha' name='fecha' errors={errors} col={[12]}>
                      <Field type='date' name='fecha' />
                    </FS> 
                    {/* <FS label="Tipo de Asistencia" name="tipo_asistencia" errors={errors} col={[12]}>
                        <Field name="tipo_asistencia" as="select">
                            <option value="1"> Presente </option> 
                            <option value="2"> Ausente </option> 
                            <option value="3"> Retirado </option> 
                            <option value="4"> Tardanza </option> 
                            <option value="5"> Excusa </option> 
                        </Field>
                    </FS> */}
                    <Col lg='12'>
                      <div className='contButtons'>
                        <Button
                          type='submit'
                          disabled={isSubmitting}
                          className='buttonSubmit'
                          style={{ marginTop: 0 }}
                        >
                          {isSubmitting ? 'Guardando...' : 'Guardar'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          centered
          show={editDate}
          onHide={() => setEditDate(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Fecha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                fecha_modificar: '',
                nueva_fecha: ''
              }}
              validationSchema={Yup.object().shape({
                fecha_modificar: Yup.string().required('La fecha a modificar es un campo requerido.'), 
                nueva_fecha: Yup.string().required('La nueva fecha es un campo requerido.'), 
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                let auth = JSON.parse(localStorage.getItem('user'));
                let access_token = auth.access_token; 
                allDates.map((date) => {
                  if(date.fecha === values.fecha_modificar){
                    axios
                    .post(API + 'asistencia/actividades/asistencia/'+ date.id + '/update', {tipo_asistencia : date.tipo_asistencia.id, fecha: values.nueva_fecha}, {
                      headers: { Authorization: 'Bearer ' + access_token },
                    })
                    .then((response) => { 
                      setTimeout(function(){
                        window.location.reload();
                      }, 500);
                    })
                    .catch((error) => {
                      console.error(`Error al registrar asistencia para participante`, error);
                    });
                  }
                })

                resetForm();
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, handleBlur, isSubmitting, errors, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <FS label="Fecha a modificar" name="fecha_modificar" errors={errors} col={[12]}>
                        <Field name="fecha_modificar" as="select">
                            <option value=""> Selecciona </option>
                              {onlyDates.map((p) => (
                                <option key={p} value={p}>{p}</option>
                              ))}
                        </Field>
                    </FS> 
                    <FS label='Nueva fecha' name='nueva_fecha' errors={errors} col={[12]}>
                      <Field type='date' name='nueva_fecha' />
                    </FS> 
                    <Col lg='12'>
                      <div className='contButtons'>
                        <Button
                          type='submit'
                          disabled={isSubmitting}
                          className='buttonSubmit'
                          style={{ marginTop: 0 }}
                        >
                          {isSubmitting ? 'Actualizando...' : 'Actualizar'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
      </>
    );
  }
  function cambiarFormatoFecha(fecha) {
    // Divide la fecha en partes
    const partes = fecha.split('-');
    
    // Asigna cada parte a una variable
    const anio = partes[0];
    const mes = partes[1];
    const dia = partes[2];
    
    // Devuelve la fecha en el nuevo formato
    return `${dia}/${mes}/${anio}`;
  }
  export default withRouter(AttendanceList);
  